:root {
   --editor-height: 200px; /* Initial value */
}

.ck .ck-balloon-panel {
   display: none !important;
}
.ck-editor__editable_inline:not(.ck-comment__input *) {
   height: var(--editor-height);
   overflow-y: auto;
}
