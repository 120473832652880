/* .ck-editor__editable_inline:not(.ck-comment__input *) {
    height: 300px; 
   overflow-y: auto;
} */
/* .parent-bc {
   height: 100%; 
   display: flex;
   flex-direction: column;
}
.ck.ckeditor-bc {
   height: 100%; 
   display: flex;
   flex-direction: column;
} */
/* .ck.ck-reset.ck-editor {
   display: flex;
   flex-direction: column;
}
.ck.ck-editor__top {
   flex-shrink: 0;
}
.ck.ck-editor__main {
   flex-grow: 1;
   position: relative;
   display: flex;
} */
.ckeditor-expand .ck.ck-content.ck-editor .ck-editor__editable,
.ckeditor-expand .ck-blurred.ck.ck-content.ck-editor__editable,
.ckeditor-expand .ck.ck-content.ck-editor__editable.ck-focused,
.ckeditor-expand .ck-editor .ck-editor__main .ck-source-editing-area {
   /* position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   height: 400px; */

   flex-grow: 1;
   display: flex;
   flex-direction: column;
   min-height: 0;
   height: 50vh;
}

.ck-editor--dark .ck-content > p {
   margin-bottom: 0;
}
.ck-editor--dark .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
   border: 1px solid #ccd2da;
   box-shadow: var(--ck-inner-shadow), 0 0;
   outline: none;
}
.ck-editor--dark .ck.ck-editor__main > .ck-editor__editable {
   background: #192030;
}
.ck-editor--dark .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
   border-color: #62707e;
}

.ck-editor--dark .ck.ck-toolbar {
   background: #62707e;
   border: 1px solid #62707e;
}
.ck-editor--dark .ck-reset_all :not(.ck-reset_all-excluded *),
.ck-editor--dark .ck.ck-reset_all {
   color: rgba(255, 255, 255, 0.65);
}
.ck-editor--dark .ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
   background: #192030;
}
.ck-editor--dark .ck.ck-content {
   color: #9ea7b1;
}
