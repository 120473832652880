/* customStyles.css */
.offcanvas-width-xl {
   --bs-offcanvas-width: 940px;
}
.form-label {
   font-weight: 600;
}
.offcanvas-width-l {
   --bs-offcanvas-width: 592px;
}
.offcanvas-width-full {
   width: 100% !important;
   max-width: none !important;
}
