.table-container {
   height: 100%;
   overflow: hidden;
}

.table-wrapper {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.table-wrapper table {
   margin: 0;
}

.table-body-wrapper {
   overflow-y: auto;
   flex: 1;
}

.table-body-wrapper table {
   border-top: none; /* Ensure no double border between thead and tbody */
}
