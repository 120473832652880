.spinner-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999; /* Ensure spinner is on top of other content */
}

svg {
   vertical-align: middle;
}

svg .svg-elem-1 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s;
}

svg.active .svg-elem-1 {
   fill: transparent;
}

svg .svg-elem-2 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s;
}

svg.active .svg-elem-2 {
   fill: transparent;
}

svg .svg-elem-3 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s;
}

svg.active .svg-elem-3 {
   fill: transparent;
}

svg .svg-elem-4 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s;
}

svg.active .svg-elem-4 {
   fill: transparent;
}

svg .svg-elem-5 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s;
}

svg.active .svg-elem-5 {
   fill: transparent;
}

svg .svg-elem-6 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg-elem-6 {
   fill: transparent;
}

svg .svg-elem-7 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9000000000000001s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9000000000000001s;
}

svg.active .svg-elem-7 {
   fill: transparent;
}

svg .svg-elem-8 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg-elem-8 {
   fill: transparent;
}

svg .svg-elem-9 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg-elem-9 {
   fill: transparent;
}

svg .svg-elem-10 {
   fill: transparent;
   -webkit-transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
   transition: fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
}

svg.active .svg-elem-10 {
   fill: transparent;
}
