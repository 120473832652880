[data-skin="dark"] .btn-close {
   filter: invert(0) !important;
}

.toast-header {
   .btn-close {
      transform: scale(0.8);
   }
}
.bc-toasts-success {
   width: 500px;
   background-image: linear-gradient(to right, #b7e7b7, white) !important;
}
.bc-toasts-error {
   width: 500px;
   background-image: linear-gradient(to right, #e7b7b7, white) !important;
}
.bc-toasts-warning {
   background-image: linear-gradient(to right, #e5e7b7, white) !important;
}
.bc-toasts {
   width: 500px;
   color: rgb(110 121 133) !important;
}
.bc-toasts-header {
   border-bottom: 1px solid whitesmoke !important;
   width: 500px;
   color: rgb(110 121 133) !important;
}
