.collapseCount {
   position: absolute;
   top: -2px;
   right: -13px;
   width: 14px;
   height: 14px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 100%;
   background-color: transparent;
   color: #6e7985;
   font-size: 9px;
   font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.cursor-pointer {
   cursor: pointer;
}
