.radial-gradient::before {
   content: "";
   position: absolute;
   height: 100%;
   width: 100%;
   opacity: 0.3;
   background: radial-gradient(rgb(210, 241, 223), rgb(211, 215, 250), rgb(186, 216, 244)) 0% 0%/400% 400%;
   animation: 15s ease 0s infinite normal none running gradient;
}
.auth-max-width {
   width: 100%;
   max-width: 400px;
}
.fs-7 {
   font-size: 0.75rem;
}
.bg-body {
   background-color: white;
}
img,
svg {
   vertical-align: middle;
}
.logo {
   height: 50px;
}
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
   color: #468847;
   background-color: #dff0d8;
   border: 1px solid #d6e9c6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
   color: #b94a48;
   background-color: #f2dede;
   border: 1px solid #eed3d7;
}

.parsley-errors-list {
   margin: 2px 0 3px;
   padding: 5px 0 0 0;
   list-style-type: none;
   font-size: 0.9em;
   line-height: 0.9em;
   opacity: 0;
   color: #b94a48;
   transition: all 0.3s ease-in;
   -o-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
   -webkit-transition: all 0.3s ease-in;
}

.parsley-errors-list.filled {
   opacity: 1;
}
.grecaptcha-badge {
   visibility: hidden;
}

@-webkit-keyframes animate-svg-stroke-1 {
   0% {
      stroke-dashoffset: 262px;
      stroke-dasharray: 262px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 262px;
   }
}
@keyframes animate-svg-stroke-1 {
   0% {
      stroke-dashoffset: 262px;
      stroke-dasharray: 262px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 262px;
   }
}
@-webkit-keyframes animate-svg-fill-1 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(0, 142, 204);
   }
}
@keyframes animate-svg-fill-1 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(0, 142, 204);
   }
}
.svg-elem-1 {
   -webkit-animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both, animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
   animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both, animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}
@-webkit-keyframes animate-svg-stroke-2 {
   0% {
      stroke-dashoffset: 262px;
      stroke-dasharray: 262px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 262px;
   }
}
@keyframes animate-svg-stroke-2 {
   0% {
      stroke-dashoffset: 262px;
      stroke-dasharray: 262px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 262px;
   }
}
@-webkit-keyframes animate-svg-fill-2 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(0, 142, 204);
   }
}
@keyframes animate-svg-fill-2 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(0, 142, 204);
   }
}
.svg-elem-2 {
   -webkit-animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
   animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both, animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}
@-webkit-keyframes animate-svg-stroke-3 {
   0% {
      stroke-dashoffset: 262px;
      stroke-dasharray: 262px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 262px;
   }
}
@keyframes animate-svg-stroke-3 {
   0% {
      stroke-dashoffset: 262px;
      stroke-dasharray: 262px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 262px;
   }
}
@-webkit-keyframes animate-svg-fill-3 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(0, 142, 204);
   }
}
@keyframes animate-svg-fill-3 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(0, 142, 204);
   }
}
.svg-elem-3 {
   -webkit-animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
   animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both, animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}
@-webkit-keyframes animate-svg-stroke-4 {
   0% {
      stroke-dashoffset: 212.78396606445312px;
      stroke-dasharray: 212.78396606445312px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 212.78396606445312px;
   }
}
@keyframes animate-svg-stroke-4 {
   0% {
      stroke-dashoffset: 212.78396606445312px;
      stroke-dasharray: 212.78396606445312px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 212.78396606445312px;
   }
}
@-webkit-keyframes animate-svg-fill-4 {
   0% {
      fill: transparent;
   }

   100% {
      fill: rgb(77, 179, 220);
   }
}
@keyframes animate-svg-fill-4 {
   0% {
      fill: transparent;
   }

   100% {
      fill: rgb(77, 179, 220);
   }
}
.svg-elem-4 {
   -webkit-animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
   animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both, animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}
@-webkit-keyframes animate-svg-stroke-5 {
   0% {
      stroke-dashoffset: 199.3759765625px;
      stroke-dasharray: 199.3759765625px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 199.3759765625px;
   }
}
@keyframes animate-svg-stroke-5 {
   0% {
      stroke-dashoffset: 199.3759765625px;
      stroke-dasharray: 199.3759765625px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 199.3759765625px;
   }
}
@-webkit-keyframes animate-svg-fill-5 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(115, 194, 227);
   }
}
@keyframes animate-svg-fill-5 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(115, 194, 227);
   }
}
.svg-elem-5 {
   -webkit-animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
   animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
}
@-webkit-keyframes animate-svg-stroke-6 {
   0% {
      stroke-dashoffset: 144.780029296875px;
      stroke-dasharray: 144.780029296875px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 144.780029296875px;
   }
}
@keyframes animate-svg-stroke-6 {
   0% {
      stroke-dashoffset: 144.780029296875px;
      stroke-dasharray: 144.780029296875px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 144.780029296875px;
   }
}
@-webkit-keyframes animate-svg-fill-6 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(135, 203, 231);
   }
}
@keyframes animate-svg-fill-6 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(135, 203, 231);
   }
}
.svg-elem-6 {
   -webkit-animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
   animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both, animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
}
@-webkit-keyframes animate-svg-stroke-7 {
   0% {
      stroke-dashoffset: 111.052001953125px;
      stroke-dasharray: 111.052001953125px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 111.052001953125px;
   }
}
@keyframes animate-svg-stroke-7 {
   0% {
      stroke-dashoffset: 111.052001953125px;
      stroke-dasharray: 111.052001953125px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 111.052001953125px;
   }
}
@-webkit-keyframes animate-svg-fill-7 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(184, 223, 241);
   }
}
@keyframes animate-svg-fill-7 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(184, 223, 241);
   }
}
.svg-elem-7 {
   -webkit-animation: animate-svg-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
      animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
   animation: animate-svg-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
      animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
}
@-webkit-keyframes animate-svg-stroke-8 {
   0% {
      stroke-dashoffset: 90.03399658203125px;
      stroke-dasharray: 90.03399658203125px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 90.03399658203125px;
   }
}
@keyframes animate-svg-stroke-8 {
   0% {
      stroke-dashoffset: 90.03399658203125px;
      stroke-dasharray: 90.03399658203125px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 90.03399658203125px;
   }
}
@-webkit-keyframes animate-svg-fill-8 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(192, 229, 242);
   }
}
@keyframes animate-svg-fill-8 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(192, 229, 242);
   }
}
.svg-elem-8 {
   -webkit-animation: animate-svg-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
      animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
   animation: animate-svg-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both, animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
}
@-webkit-keyframes animate-svg-stroke-9 {
   0% {
      stroke-dashoffset: 63.17799377441406px;
      stroke-dasharray: 63.17799377441406px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 63.17799377441406px;
   }
}
@keyframes animate-svg-stroke-9 {
   0% {
      stroke-dashoffset: 63.17799377441406px;
      stroke-dasharray: 63.17799377441406px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 63.17799377441406px;
   }
}
@-webkit-keyframes animate-svg-fill-9 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(221, 237, 245);
   }
}
@keyframes animate-svg-fill-9 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(221, 237, 245);
   }
}
.svg-elem-9 {
   -webkit-animation: animate-svg-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
      animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
   animation: animate-svg-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both, animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
}
@-webkit-keyframes animate-svg-stroke-10 {
   0% {
      stroke-dashoffset: 57.16200256347656px;
      stroke-dasharray: 57.16200256347656px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57.16200256347656px;
   }
}
@keyframes animate-svg-stroke-10 {
   0% {
      stroke-dashoffset: 57.16200256347656px;
      stroke-dasharray: 57.16200256347656px;
   }
   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57.16200256347656px;
   }
}
@-webkit-keyframes animate-svg-fill-10 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(232, 240, 244);
   }
}
@keyframes animate-svg-fill-10 {
   0% {
      fill: transparent;
   }
   100% {
      fill: rgb(232, 240, 244);
   }
}
.svg-elem-10 {
   -webkit-animation: animate-svg-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
      animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
   animation: animate-svg-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
      animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
}
.number-field {
   flex-direction: row;
   column-gap: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.number-field input {
   height: 45px;
   width: 42px;
   border-radius: 6px;
   outline: none;
   font-size: 1.125rem;
   text-align: center;
   border: 1px solid #ddd;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   appearance: none;
   margin: 0; /* Optional: Remove default spacing */
}
