.custom-tabs {
   display: flex;
   justify-content: space-between;
}

.left-tabs,
.right-tabs {
   display: flex;
}

.right-tabs {
   margin-left: auto;
}

.custom-tabs .nav-item {
   margin: 0 10px;
}

.dropdown-custom .dropdown-toggle {
   white-space: nowrap; /* Prevent wrapping of text and elements */
   display: flex;
   align-items: center; /* Ensure items are aligned vertically */
}

.dropdown-custom .dropdown-toggle::after {
   margin-left: 0.5rem; /* Adjust spacing if necessary */
   vertical-align: middle; /* Aligns the triangle vertically */
}

.notes-start-date {
   width: 105px;
   min-width: 105px;
}
.note-col-2 {
   min-height: 75px;
}
.note-styles p {
   margin-bottom: 0;
}
.session-note-status-data > span:not(:last-child)::after {
   content: " .";
   padding: 0 5px;
   color: #999;
   vertical-align: super;
}
.notes-hover-nav .hover-content {
   transition: opacity 0.2s ease-in-out;
   opacity: 0;
}
.notes-hover-nav:hover .hover-content {
   opacity: 1; /* Visible on hover */
   transition: opacity 0.2s ease-in-out;
}
