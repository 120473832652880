.editor-wrapper {
   position: relative;
   height: 240px; /* Initial height */
   resize: vertical; /* Allows manual resizing by dragging the corner */
}

.resize-handle {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 10px;
   cursor: row-resize;
   border: 1px solid #ccc;
   /* background-color: #f1f1f1; */
   display: flex;
   justify-content: center;
   align-items: center;
}

.ck-editor__editable_inline {
   height: 100%;
   overflow: auto;
}
