.dropzone {
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
   border-width: 2px;
   border-radius: 2px;
   border-color: #eeeeee;
   border-style: dashed;
   background-color: #fafafa;
   color: #bdbdbd;
   outline: none;
   transition: border 0.24s ease-in-out;
}

.table-container {
   height: 50%;
   overflow: hidden;
}

.table-wrapper {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.table-wrapper table {
   margin: 0;
}

.table-body-wrapper {
   overflow-y: auto;
   flex: 1;
}

.table-body-wrapper table {
   border-top: none; /* Ensure no double border between thead and tbody */
}
